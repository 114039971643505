/**
 * Import des librairies
 * ******************************
 */
import Hammer from "hammerjs";
import Swiper, { Navigation, Pagination, EffectFade, Thumbs, Grid, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/grid";
import "reset-css";

/**
 * Import des styles et des modules
 * ****************************************
 */
import "./../sass/style.scss";

import ShowDesiredMenu from "./modules/showDesiredMenu";
import SearchProduct from "./modules/SearchProduct";
import FilterProducts from "./modules/FilterProducts";
import Partner from "./modules/Partner";
// import PartnersBlock from "./modules/PartnersBlock";
import StoreLocator from "./modules/StoreLocator";
import PartnerHomeSearch from "./modules/PartnerHomeSearch";
import ProductScrollTo from "./modules/ProductScrollTo";
import CookieFooter from "./modules/CookieFooter";
import CookieRefuse from "./modules/CookieRefuse";
import OpenCalcPartner from "./modules/OpenCalcPartner";

function doOnDocumentLoaded() {
    loaderInit();
}

if (document.readyState === "loading") {
    document.addEventListener("DOMContentLoaded", doOnDocumentLoaded);
} else {
    doOnDocumentLoaded();
}

/**
 * Initialisation des modules
 * ******************************
 */
function loaderInit() {
    /**
     * Initialisation des variables
     * ****************************************
     */
    const body = document.querySelector("[data-menu-status]") as HTMLElement;
    const header = document.querySelector(".header") as HTMLElement;
    const listing = document.querySelector(".listing") as HTMLElement;
    const mainSlider = document.querySelector(".main-slider") as HTMLElement;
    const partner = document.querySelector(".partner") as HTMLElement;
    const partnersSlider = document.querySelector(".partners-slider") as HTMLElement;
    const slider = document.querySelector(".slider") as HTMLElement;
    const thumbs = document.querySelector(".thumbs") as HTMLElement;
    const homeServicesBlock = document.querySelector<HTMLElement>(".block--services");
    const homeBrandsBlock = document.querySelector<HTMLElement>(".block--brands");
    const homeProductFamiliesBlock = document.querySelector<HTMLElement>(".block--home-products");
    let partnersBlock = document.querySelector(".partners-block .view-content") as HTMLElement;

    const partnerSearch = document.querySelector(".page-node-type-homepage #securom-search") as HTMLInputElement | null;
    const ScrollToElem = document.querySelector(".button-scroll-to") as HTMLAnchorElement;
    const OpenCookie = document.querySelector(".open-cookies") as HTMLLinkElement;
    let Refuse = document.querySelector(".refuse-all") as HTMLElement;

    ProductScrollTo(ScrollToElem);

    SearchProduct(header);

    OpenCalcPartner();

    /**
     * Gestion des sliders de la HP et des pages de contenus riches
     * ****************************************
     */
    if (slider) {
        new Swiper(".slider", {
            loop: true,
            spaceBetween: 0,
            autoplay: {
                delay: 4000,
            },
            pagination: {
                el: ".block__pagination-main-slider",
                clickable: true,
            },

            // configure Swiper to use modules
            modules: [Navigation, Pagination, EffectFade, Autoplay],
        });
    }

    /**
     * Home partner search
     */
    if (partnerSearch) {
        PartnerHomeSearch(partnerSearch);
    }

    /**
     * Home services block
     */
    if (homeServicesBlock) {
        new Swiper(".block--services .swiper-container", {
            spaceBetween: 32,
            slidesPerView: 1.25,

            breakpoints: {
                0: {
                    spaceBetween: 16,
                },
                480: {
                    slidesPerView: 1,
                },
                768: {
                    slidesPerView: 3,
                },
            },

            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },

            // configure Swiper to use modules
            modules: [Navigation, Pagination, EffectFade],
        });
    }

    /**
     * Home brands block
     */
    if (homeBrandsBlock) {
        let brandSwiper = new Swiper(".block--brands .swiper-container", {
            spaceBetween: 32,
            slidesPerView: 2.25,

            breakpoints: {
                0: {
                    spaceBetween: 16,
                },
                480: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 5,
                },
            },

            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },

            // configure Swiper to use modules
            modules: [Navigation, Pagination, EffectFade],
        });
        // Update on resize.
        addEventListener("resize", (event) => {
            if (event && brandSwiper) {
                brandSwiper.update();
            }
        });
    }

    /**
     * Home product families block
     */
    if (homeProductFamiliesBlock) {
        let homeProductFamiliesSwiper = new Swiper(".block--home-products .swiper-container", {
            spaceBetween: 32,
            slidesPerView: 1.25,

            breakpoints: {
                0: {
                    spaceBetween: 16,
                },
                480: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 3,
                },
            },

            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },

            // configure Swiper to use modules
            modules: [Navigation, Pagination, EffectFade],
        });
        // Update on resize.
        addEventListener("resize", (event) => {
            if (event && homeProductFamiliesSwiper) {
                homeProductFamiliesSwiper.update();
            }
        });
    }

    /**
     * Partners slider
     */
    if (partnersSlider) {
        let partnerSwiper = new Swiper(".partners-slider", {
            loop: false,
            slidesPerView: 1,
            grid: {
                rows: 3,
            },

            pagination: {
                el: ".partners-slider__pagination",
                clickable: true,
                type: "bullets",
            },

            breakpoints: {
                480: {
                    slidesPerView: 2,
                },
                1200: {
                    slidesPerView: 4,
                },
            },

            // configure Swiper to use modules
            modules: [Grid, Pagination],
        });
        // Update on resize.
        addEventListener("resize", (event) => {
            if (event && partnerSwiper) {
                partnerSwiper.update();
            }
        });
    }

    /**
     * Gestion des sliders présents seulement en mobile (exemple : Cards services sur une page partenaire)
     * Gestion de multiple instances de swiper avec pagination sur la même page
     * ****************************************
     */

    (function () {
        "use strict";

        // breakpoint where swiper will be destroyed
        // and switches to a dual-column layout
        const breakpoint = window.matchMedia("(min-width:768px)");

        // keep track of swiper instances to destroy later
        let mySwiper: any;

        const breakpointChecker = function () {
            // if larger viewport and multi-row layout needed
            if (breakpoint.matches === true) {
                // clean up old instances and inline styles when available
                if (mySwiper !== undefined) mySwiper.destroy(true, true);

                // or/and do nothing
                return;

                // else if a small viewport and single column layout needed
            } else if (breakpoint.matches === false) {
                // fire small viewport version of swiper
                return enableSwiper();
            }
        };

        const enableSwiper = function () {
            let largeSliders = document.querySelectorAll(".swiper-container-mobile");
            let pagination = document.querySelectorAll(".swiper-pagination--mobile");

            largeSliders.forEach((slider, index) => {
                let sliderLength = slider.children[0].children.length;
                let result = sliderLength > 1 ? true : false;

                // @ts-ignore
                mySwiper = new Swiper(slider, {
                    loop: false,
                    slidesPerView: "auto",
                    centeredSlides: false,
                    keyboardControl: true,
                    grabCursor: true,
                    spaceBetween: 24,
                    pagination: {
                        el: pagination[index],
                        type: "bullets",
                        clickable: true,
                    },

                    // configure Swiper to use modules
                    modules: [Navigation, Pagination, EffectFade],
                });
            });
        };
        // keep an eye on viewport size changes
        breakpoint.addListener(breakpointChecker);

        // kickstart
        breakpointChecker();
    })(); /* IIFE end */

    /**
     * Affichage du menu souhaité
     * ****************************************
     */
    ShowDesiredMenu(body, header);

    /**
     * Affichage des filtres produits.
     * ****************************************
     */
    FilterProducts(listing);

    /**
     * Affichage du store locator.
     * ****************************************
     */
    StoreLocator();

    if (partner) {
        Partner();
    }

    /**
     * Single product and single partner page slider
     * ****************************************
     */
    if (mainSlider && thumbs) {
        let swiper = new Swiper(".thumbs", {
            spaceBetween: 16,
            slidesPerView: 2,
            direction: getDirection(),
            navigation: {
                nextEl: ".thumbs__nav--next",
                prevEl: ".thumbs__nav--prev",
            },
            on: {
                resize: function () {
                    swiper.changeDirection(getDirection());
                },
            },
            breakpoints: {
                320: {
                    slidesPerView: 3,
                },
                480: {
                    slidesPerView: 4,
                },
                580: {
                    slidesPerView: 5,
                },
            },
            modules: [Navigation, Pagination, EffectFade],
        });

        // Main slider
        let swiper2 = new Swiper(".main-slider", {
            spaceBetween: 10,
            speed: 1,
            effect: "fade",
            thumbs: {
                swiper: swiper,
            },
            modules: [Navigation, Pagination, EffectFade, Thumbs],
        });

        // Change slider direction on mobile
        function getDirection() {
            const direction = window.innerWidth <= 768 ? "horizontal" : "vertical";

            return direction;
        }
    }

    /**
     * Partners block
     */
    // if (partnersBlock) {
    //     PartnersBlock(Hammer, partnersBlock);
    //     document.addEventListener("DOMSubtreeModified", function () {
    //         partnersBlock = document.querySelector(".partners-block .view-content") as HTMLElement;
    //         PartnersBlock(Hammer, partnersBlock);
    //     });
    // }

    /**
     * Footer cookie.
     */
    if (OpenCookie) {
        CookieFooter(OpenCookie);
    }
    if (Refuse) {
        CookieRefuse(Refuse);
    }
    document.addEventListener("DOMSubtreeModified", function () {
        Refuse = document.querySelector(".refuse-all") as HTMLElement;
        CookieRefuse(Refuse);
    });
}
