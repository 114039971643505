export default function Partner() {
  const dt = new Date();
  const dayOfWeekName = dt.toLocaleString("fr-FR", { weekday: "long" });
  const horaires = JSON.parse(window.horaire);
  const opening = document.querySelector(".partner .opening") as HTMLElement; 
  
  opening.innerHTML = "Actuellement fermé";

  // Foreach.
  horaires[dayOfWeekName].forEach(function (element: any) {
    // Variables manipulations.
    const start = element.de.split("h");
    const end = element.a.split("h");
    const startTime =
      (start[0] ? start[0] : "00") + ":" + (start[1] ? start[1] : "00") + ":00";
    const endTime =
      (end[0] ? end[0] : "00") + ":" + (end[1] ? end[1] : "00") + ":00";

    var s = startTime.split(":");
    var dt1 = new Date(
      dt.getFullYear(),
      dt.getMonth(),
      dt.getDate(),
      parseInt(s[0]),
      parseInt(s[1]),
      parseInt(s[2])
    );

    var e = endTime.split(":");
    var dt2 = new Date(
      dt.getFullYear(),
      dt.getMonth(),
      dt.getDate(),
      parseInt(e[0]),
      parseInt(e[1]),
      parseInt(e[2])
    );

    if (dt >= dt1 && dt <= dt2) {
      opening.classList.add("valid");
      const lastline = horaires[dayOfWeekName].slice(-1);
      opening.innerHTML = "Ouvert aujourd'hui jusqu'à " + lastline[0].a;
    }

  });
}
