export default function PartnerHomeSearch(elem: HTMLInputElement | undefined) {
  const errorDiv = document.querySelector(
    ".block__search .error"
  ) as HTMLElement;
  const button = document.querySelector(
    ".block__search [data-target]"
  ) as HTMLButtonElement;

  if (elem) {
    elem.onkeyup = (event: KeyboardEvent) => {
      const value = elem?.value;
      const string = button.getAttribute("data-target");

      if (string && value) {
        // Create url.
        let url = new URL(window.location.origin + string);

        if (value.length >= 3) {
          errorDiv.hidden = true;
          button.disabled = false;

          // Add or replace param search.
          url.searchParams.set("search", value);
          button.setAttribute("data-url", url.toString());

          if (event.key === "Enter") {
            button.click();
          }
        } else {
          button.disabled = true;
          errorDiv.hidden = false;
        }
      }
    };

    // Event on click.
    button.onclick = () => {
      if (button.disabled === true) return;
      const url = button.dataset.url;
      (<any>window).location.href = url;
    };
  }
}
