export default function OpenCalcPartner() {
  let elementsArray = document.querySelectorAll(".card_calc_partner");

  elementsArray.forEach(function(elem) {
    elem.addEventListener("click", function(): void|false {
      //this function does stuff
      // If it's an iPhone.
      let part = elem.closest('.card') as HTMLElement;
      if (part.dataset.longitude && part.dataset.latitude) {
        const userAgent = navigator.userAgent || navigator.vendor;
        const place = part.querySelector('.card__description span') as HTMLElement;
        const value = place.innerText?.replace(/\s+/g, '+').trim();
        const urlIos = `https://maps.apple.com/maps?t=r&daddr=${value}&dirflg=d&sll=${part.dataset.latitude},${part.dataset.longitude}&z=10`;
        const urlGmaps = `https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${part.dataset.latitude},${part.dataset.longitude}`;
        if (/android/i.test(userAgent)) {
          window.open(urlGmaps);
        }
        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          window.open(urlIos);
        } else {
          window.open(urlGmaps);
        }
      }

    });
  });

}
