export default function SearchProduct(header: HTMLElement) {
  /**
   * Initialisation des variables.
   * ****************************************
   */
  const minSearchLength = 3;
  const maxDisplayResults = 5;
  const searchInput = header.querySelector<HTMLInputElement>(
    'input[name="search-product"]'
  );
  const searchButton =
    header.querySelector<HTMLInputElement>(".search__button");

  const searchResultsContainer =
    header.querySelector<HTMLElement>(".search__results");

  if (header && searchInput && searchResultsContainer) {
    /**
     * Lancement de la recherche.
     * ****************************************
     */
    searchInput.addEventListener("input", search);

    searchInput.addEventListener("keypress", function (event:KeyboardEvent) {
      if (event.key === 'Enter') {
        searchButton?.click();
      }
    });

    /**
     * Suppression de la recherche.
     * ****************************************
     */
    document.addEventListener("click", cleanSearchResults);
  }

  async function search(e: Event) {
    /**
     * Récupération de la valeur de l'input recherche.
     * ****************************************
     */
    const inputValue = getInputValue(e);

    /**
     * Insertion du nouveau lien de recherche.
     * ****************************************
     */
    updateSearchButtonValue(inputValue);

    if (inputValue?.length < minSearchLength) {
      /**
       * Suppression des anciens résultats de recherche.
       * ****************************************
       */
      cleanSearchResults();

      return;
    }

    /**
     * Récupération des produits et des marques depuis le endpoint.
     * ****************************************
     */
    const searchResults = await getSearchResults(inputValue);

    /**
     * Insertion des résultats de la recherche dans le DOM.
     * ****************************************
     */
    renderSearchResults(searchResults, inputValue);
  }

  function getInputValue(e: Event) {
    return (e.target as HTMLInputElement).value;
  }

  async function getSearchResults(inputValue: string) {
    return await fetch(
      `/search_api_autocomplete/search_product?display=block&&filter=search&q=${inputValue}`
    ).then((res) => res.json());
  }

  function renderSearchResults(searchResults: any, inputValue: string) {
    let renderedProducts = "";
    let renderedBrands = "";
    const products = searchResults.product ? searchResults.product : [];
    const brands = searchResults.brand ? searchResults.brand : [];
    const productsToDisplay =
      products.length > maxDisplayResults ? maxDisplayResults : products.length;
    const brandsToDisplay =
      brands.length > maxDisplayResults ? maxDisplayResults : brands.length;
    const showAllLink = `<a class="show-all" href="/rechercher-un-produit?search=${inputValue}">Voir tous les résultats</a>`;

    /**
     * Suppression des précédents résultats de recherche.
     * ****************************************
     */
    cleanSearchResults();

    /**
     * Génaration des résultats de recherche "produit".
     * ****************************************
     */
    if (productsToDisplay > 0) {
      let showAllProductsLink = "";

      if (productsToDisplay == 5) {
        showAllProductsLink = showAllLink;
      }

      for (let i = 0; i < productsToDisplay; i++) {
        renderedProducts += renderSearchItem(products[i]);
      }

      /**
       * Insertion des résultats de recherche "produit".
       * ****************************************
       */
      if (searchResultsContainer) {
        searchResultsContainer.innerHTML += `
          <div class="group">
            <p class="group__name">Produits</p>
            <div class="group__results">${renderedProducts}${showAllProductsLink}</div>
          </div>
        `;
      }
    }

    /**
     * Génaration des résultats de recherche "marque".
     * ****************************************
     */
    if (brandsToDisplay > 0) {
      let showAllBrandsLink = "";

      for (let i = 0; i < brandsToDisplay; i++) {
        renderedBrands += renderSearchItem(brands[i]);
      }

      if (brandsToDisplay == 5) {
        showAllBrandsLink = showAllLink;
      }

      /**
       * Insertion des résultats de recherche "marque".
       * ****************************************
       */
      if (searchResultsContainer) {
        searchResultsContainer.innerHTML += `
          <div class="group">
            <p class="group__name">Marques</p>
            <div class="group__results">${renderedBrands}${showAllBrandsLink}</div>
          </div>
        `;
      }
    }
  }

  function renderSearchItem(item: SearchApiResult) {
    return `<a href="${item.url}">${item.value}</a>`;
  }

  function cleanSearchResults() {
    if (searchResultsContainer) {
      searchResultsContainer.innerHTML = "";
    }
  }

  function updateSearchButtonValue(inputValue: string) {
    searchButton?.setAttribute(
      "href",
      `/rechercher-un-produit?search=${inputValue}`
    );
  }
}
