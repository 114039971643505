export default function FilterProducts(listing: any) {
  // Eventlistener ajax.
  if (listing) {
    document.addEventListener("DOMSubtreeModified", function () {
      const resetProductButton = document.querySelector(
        ".triggerAll"
      ) as HTMLElement;

      const triggerReset = document.querySelector("#edit-reset") as HTMLElement;

      const isChecked = document.querySelector(
        ".form-checkbox:checked"
      ) as HTMLElement;

      if (isChecked) {
        resetProductButton.classList.remove("selected");
      }

      resetProductButton.onclick = (e: any) => {
        e.preventDefault();
        window.location.replace(location.pathname);
      };
    });
  }
}
