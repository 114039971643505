export default function CookieRefuse(refuse: HTMLElement) {
  if (refuse) {
    refuse.onclick = function (e: MouseEvent) {
      e.preventDefault();
      const categories = Array.from(document.querySelectorAll<HTMLElement>('.eu-cookie-compliance-category input:not([disabled])'));
      categories.forEach((cat: any) => {
        cat.checked = false;
      });
      const buttonValid = document.querySelector('.eu-cookie-compliance-save-preferences-button') as HTMLInputElement;
      buttonValid.click();
    }
  }
}
