export default function ProductScrollTo(elem: HTMLAnchorElement) {
  if (elem) {
    if(window.location.hash) {
      const hash = window.location.hash;
      const target = document.querySelector(hash) as HTMLElement;
      const headerOffset = 120;
      const elementPosition = target.offsetTop;
      const offsetPosition = elementPosition - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }

    elem.onclick = function(e: MouseEvent) {
      e.preventDefault();
      const hash = elem.hash;
      const target = document.querySelector(hash) as HTMLElement;
      const headerOffset = 120;
      const elementPosition = target.offsetTop;
      const offsetPosition = elementPosition - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  }
}
