import mapboxgl from "mapbox-gl";
// @ts-ignore
import MapboxLanguage from "@mapbox/mapbox-gl-language";
import "mapbox-gl/dist/mapbox-gl.css";
import * as turf from "@turf/turf";
export default async function StoreLocator() {
  /**
   * Configuration du Store Locator.
   * ****************************************
   */
  let userLocation: any = {};
  const mapboxAccessToken: string = import.meta.env.VITE_MAPBOX_API_KEY;
  const mapboxAPI: string = "https://api.mapbox.com/";
  const minSearchLength: number = 3;
  const mapConfiguration: MapConfiguration = {
    latitude: 46.232192999999995,
    longitude: 2.209666999999996,
    defaultZoom: 5,
    minZoom: 4,
    selectedStoreZoom: 16,
    multipleSelectedStoreZoom: 6,
  };

  /**
   * Récupération des éléments HTML génériques du store locator.
   * ****************************************
   */
  const storeLocator = document.querySelector<HTMLElement>(".store-locator");
  const storeLocatorMap = storeLocator?.querySelector<HTMLElement>(
    ".store-locator__map"
  );
  const storeLocatorList = storeLocator?.querySelector<HTMLElement>(
    ".store-locator__list"
  );

  if (!storeLocatorList) return;

  const storeListItems = Array.from(
    storeLocatorList?.querySelectorAll<HTMLElement>(".card--store")
  );

  /**
   * Récupération des éléments HTML liés à la recherhe par emplacement.
   * ****************************************
   */
  const searchByLocation = storeLocator?.querySelector<HTMLElement>(
    ".search__item-places"
  );
  const locationInput =
    searchByLocation?.querySelector<HTMLInputElement>(".search__input");
  const locationButton =
    searchByLocation?.querySelector<HTMLElement>(".search__button");
  const locationsWrapper =
    searchByLocation?.querySelector<HTMLElement>(".search__results");
  const locationsSearchResultsWrapper =
    locationsWrapper?.querySelector<HTMLElement>(".group__results");
  const locateMe =
    searchByLocation?.querySelector<HTMLElement>("[data-locate-me]");

  /**
   * Initialisation des variables.
   * ****************************************
   */
  let autoSearch: boolean = false;
  let searchValue: string = "";
  let targetedLocation: HTMLElement | undefined = undefined;

  /**
   * Vérification de l'éxistence de tous les éléments requis.
   * ****************************************
   */
  if (
    !mapboxAccessToken ||
    !storeLocator ||
    !storeLocatorList ||
    !storeLocatorMap ||
    !locationInput ||
    !locationButton ||
    !locationsWrapper ||
    !locationsSearchResultsWrapper
  )
    return;

  /**
   * Récupération des magasins depuis le DOM.
   * ****************************************
   */
  const stores = getStores(storeListItems);

  if (stores.length == 0) return;

  /**
   * Authentification auprès de l'API Mapbox.
   * ****************************************
   */
  mapboxgl.accessToken = mapboxAccessToken;

  /**
   * Configuration de la carte.
   * ****************************************
   */
  const map = new mapboxgl.Map({
    container: "store-locator-map",
    style: "mapbox://styles/mapbox/light-v10",
    center: [mapConfiguration.longitude, mapConfiguration.latitude],
    zoom: mapConfiguration.defaultZoom,
    minZoom: mapConfiguration.minZoom,
  });

  /**
   * Traduction de la carte.
   * ****************************************
   */
  const language = new MapboxLanguage();
  map.addControl(language);

  /**
   * Lancement de divers actions au chargement de la carte.
   * ****************************************
   */
  map.on("load", () => {
    /**
     * Ajout des marqueurs sur la carte.
     * ****************************************
     */
    setMarkers(map, mapConfiguration, stores, storeListItems);

    /**
     * Ajout des ancres permettant de faire le lien entre la liste et la carte.
     * ****************************************
     */
    setAnchorsToStoreSuggestions(map, mapConfiguration, storeListItems);

    locationInput.oninput = async () => {
      searchValue = getInputValue(locationInput);

      if (searchValue.length < minSearchLength) {
        /**
         * Repositionnement de la carte à son état initial.
         * ****************************************
         */
        resetMapPosition(map, mapConfiguration);

        const markers: HTMLElement[] = getMarkers(storeLocatorMap);
        if (markers.length > 0) {
          removeLocationMarker(markers);
          showMarkers(markers);
        }

        /**
         * Suppression des anciennes suggestions de lieux.
         * ****************************************
         */
        cleanLocationSuggestions(locationsSearchResultsWrapper);

        /**
         * Suppression des anciennes suggestions de magasins.
         * ****************************************
         */
        cleanStoreSuggestions(storeListItems);

        return;
      }

      /**
       * Désactive ou active la recherche automatique en fonction du paramètre présent dans l'url.
       * ****************************************
       */
      if (!url.searchParams.get("search")) {
        autoSearch = false;
      } else {
        autoSearch = true;
        url.searchParams.delete("search");
      }

      /**
       * Lancement de la recherche.
       * ****************************************
       */
      makeSearch(
        map,
        mapboxAPI,
        mapboxAccessToken,
        locationInput,
        locationButton,
        locationsWrapper,
        locationsSearchResultsWrapper,
        stores,
        storeLocatorMap,
        storeListItems,
        targetedLocation,
        searchValue,
        autoSearch
      );
    };

    /**
     * Affichage / masquage des suggestions de lieux.
     * ****************************************
     */
    document.onclick = (e: Event) =>
      openLocationSuggestions(e, locationsWrapper, locationInput);

    /**
     * Test if searchParam exists in url.
     * ****************************************
     */
    const url = new URL(window.location.href);

    if (url.searchParams.get("search")) {
      const searchParam = url.searchParams.get("search");
      if (searchParam && locationInput) {
        locationInput.value = searchParam;
        locationInput.dispatchEvent(new Event("input"));
      }
    }

    /**
     * User geolocation.
     * ****************************************
     */
    if (locateMe) {
      locateMe.onclick = async () => {
        userLocation = await getUserLocation(mapboxAPI, mapboxAccessToken);

        if (!userLocation.features[0].place_name) return;

        searchValue = userLocation.features[0].place_name;

        /**
         * Active la recherche automatique.
         * ****************************************
         */
        autoSearch = true;

        /**
         * Lancement de la recherche.
         * ****************************************
         */
        makeSearch(
          map,
          mapboxAPI,
          mapboxAccessToken,
          locationInput,
          locationButton,
          locationsWrapper,
          locationsSearchResultsWrapper,
          stores,
          storeLocatorMap,
          storeListItems,
          targetedLocation,
          searchValue,
          autoSearch
        );

        locationInput.focus();
        locationInput.value = searchValue;
      };
    }
  });

  /**
   * Réinitialisation des suggestions de magasins au zoom.
   * ****************************************
   */
  map.on("wheel", () => {
    const markers: HTMLElement[] = getMarkers(storeLocatorMap);
    if (markers.length > 0) {
      removeLocationMarker(markers);
      showMarkers(markers);
    }
    cleanStoreSuggestions(storeListItems);
  });

  /**
   * Réinitialisation des suggestions de magasins au déplacement.
   * ****************************************
   */
  map.on("dragend", () => {
    const markers: HTMLElement[] = getMarkers(storeLocatorMap);
    if (markers.length > 0) {
      removeLocationMarker(markers);
      showMarkers(markers);
    }
    cleanStoreSuggestions(storeListItems);
  });
}

/**
 * Récupération des magasins depuis l'HTML.
 * ****************************************
 */
function getStores(storeListItems: HTMLElement[]) {
  const stores: Store[] = [];

  storeListItems.forEach((storeListItem: HTMLElement) => {
    const id = storeListItem.dataset.id;
    const name = storeListItem.dataset.name;
    const longitude = storeListItem.dataset.longitude;
    const latitude = storeListItem.dataset.latitude;
    const referentOf = storeListItem.dataset.referentOf;
    const departments: string[] = [];

    if (referentOf) {
      JSON.parse(referentOf).forEach((department: string) => {
        departments.push(department);
      });
    }

    /**
     * Afficher les horaires.
     */
    const hours = storeListItem.querySelector<HTMLElement>(".card__days");
    
    const dt = new Date();

    const opening = storeListItem.querySelector<HTMLElement>(
      ".partner__opening.opening"
    );

    if (opening && hours && hours.hasAttribute("data-hour")) {
      const daytime = hours.getAttribute("data-hour");
      if (daytime) {
        const array = JSON.parse(daytime);
        array.forEach(function (element: any) {
          const start = element.de.split("h");
          const end = element.a.split("h");
          const startTime =
            (start[0] ? start[0] : "00") +
            ":" +
            (start[1] ? start[1] : "00") +
            ":00";
          const endTime =
            (end[0] ? end[0] : "00") + ":" + (end[1] ? end[1] : "00") + ":00";

          var s = startTime.split(":");
          var dt1 = new Date(
            dt.getFullYear(),
            dt.getMonth(),
            dt.getDate(),
            parseInt(s[0]),
            parseInt(s[1]),
            parseInt(s[2])
          );

          var e = endTime.split(":");
          var dt2 = new Date(
            dt.getFullYear(),
            dt.getMonth(),
            dt.getDate(),
            parseInt(e[0]),
            parseInt(e[1]),
            parseInt(e[2])
          );

          if (dt >= dt1 && dt <= dt2) {
            opening.classList.add("valid");
            opening.innerHTML = "Ouvert aujourd'hui";
          }
        });
      }
    }

    if (!id || !name || !longitude || !latitude) return;

    const store: Store = {
      id: parseInt(id),
      name: name,
      coordinates: {
        longitude: parseFloat(longitude),
        latitude: parseFloat(latitude),
      },
      referentOf: departments,
    };

    stores[store.id] = store;
  });

  return stores;
}

/**
 * Génération de la liste les marqueurs.
 * ****************************************
 */
function setMarkers(
  map: any,
  mapConfiguration: MapConfiguration,
  stores: Store[],
  storeListItems: HTMLElement[]
) {
  stores.forEach((store: Store) => {
    const marker: HTMLAnchorElement = document.createElement("a");
    const id = store.id;
    const suggestedStore: any[] = [];
    suggestedStore[id] = stores[id];

    /**
     * Insertion de l'ID du magasin associé.
     * ****************************************
     */
    marker.dataset.storeId = id.toString();

    /**
     * Insertion du marqueur dans le DOM.
     * ****************************************
     */
    new mapboxgl.Marker(marker)
      .setLngLat([store.coordinates.longitude, store.coordinates.latitude])
      .addTo(map);

    /**
     * Repositionnement de la carte au clique sur un marqueur.
     * ****************************************
     */
    marker.onclick = () => {
      flyTo(
        map,
        store.coordinates.longitude,
        store.coordinates.latitude,
        mapConfiguration.selectedStoreZoom
      );

      updateStoreSuggestions(storeListItems, suggestedStore);
    };
  });
}

function getMarkers(storeLocatorMap: HTMLElement) {
  const markers = Array.from(
    storeLocatorMap.querySelectorAll<HTMLElement>(".mapboxgl-marker")
  );

  return markers;
}

function hideMarkers(markers: HTMLElement[]) {
  markers.forEach((marker: HTMLElement) => {
    marker.hidden = true;
  });
}

function showMarkers(markers: HTMLElement[], suggestedStores: Store[] = []) {
  markers.forEach((marker: HTMLElement, index: number) => {
    if (suggestedStores.length > 0) {
      const id: number | undefined = marker?.dataset.storeId
        ? parseInt(marker?.dataset.storeId)
        : undefined;
      if (!id || !suggestedStores.hasOwnProperty(id)) return;
    }

    markers[index].hidden = false;
  });
}

function setLocationMarker(map: any, place: Place) {
  const marker: HTMLSpanElement = document.createElement("span");
  marker.dataset.place = place.name;
  new mapboxgl.Marker(marker)
    .setLngLat([place.coordinates.longitude, place.coordinates.latitude])
    .addTo(map);
}

function removeLocationMarker(markers: HTMLElement[]) {
  markers.forEach((marker: HTMLElement) => {
    if (!marker.dataset.place) return;
    marker.remove();
  });
}

/**
 * Récupération de la valeur d'un champ de formulaire.
 * ****************************************
 */
function getInputValue(input: HTMLInputElement) {
  return input.value;
}

/**
 * Ajout des ancres permettant de faire le lien entre la liste et la carte.
 * ****************************************
 */
function setAnchorsToStoreSuggestions(
  map: any,
  mapConfiguration: MapConfiguration,
  storeListItems: HTMLElement[]
) {
  storeListItems.forEach((storeListItem: HTMLElement) => {
    storeListItem.onclick = (e: MouseEvent) => {
      let elem = e.target as HTMLElement;
      if (elem.classList.contains("card_calc_partner"))
        return;
      if (!storeListItem.dataset.longitude || !storeListItem.dataset.latitude)
        return;

      const longitude: number = parseFloat(storeListItem.dataset.longitude);
      const latitude: number = parseFloat(storeListItem.dataset.latitude);
      flyTo(map, longitude, latitude, mapConfiguration.selectedStoreZoom);
    };
  });
}

/**
 * Récupération des suggestions de lieux liés à la recherche.
 * ****************************************
 */
async function getLocationSuggestions(
  mapboxAccessToken: string,
  mapboxAPI: string,
  searchValue: string
) {
  const params = `${searchValue}.json?country=fr&language=fr&types=place,postcode&limit=10`;
  const request = `${mapboxAPI}geocoding/v5/mapbox.places/${params}&access_token=${mapboxAccessToken}`;
  const response = await fetch(request).then((res) => res.json());

  return response;
}

/**
 * Insertion des suggestions de lieux.
 * ****************************************
 */
function insertLocations(
  locationsSearchResultsWrapper: HTMLElement,
  searchResultsLocations: any[]
) {
  if (searchResultsLocations.length == 0) return;

  let locations: string = "";

  for (let i = 0; i < searchResultsLocations.length; i++) {
    locations += generateLocation(searchResultsLocations[i]);
  }

  locationsSearchResultsWrapper.innerHTML += locations;
}

/**
 * Génération d'une suggestion de lieu.
 * ****************************************
 */
function generateLocation(place: any) {
  let department = null;
  let type = place.place_type[0];
  let suggestion = "";

  if (!place.center) return suggestion;

  if (type === "region") {
    department = place.properties.short_code.substr(3, 4);
  } else if (type === "place") {
    department = place.context[0].short_code.substr(3, 4);
  } else if (type === "postcode") {
    department = place.context[1].short_code.substr(3, 4);
  } else {
    return suggestion;
  }

  suggestion = `<li class="group__result" tabindex="0" data-name="${place.place_name}" data-longitude="${place.center[0]}" data-latitude="${place.center[1]}" data-department="${department}">${place.place_name}</li>`;

  return suggestion;
}

/**
 * Affichage / masquage des suggestions de lieux.
 * ****************************************
 */
function openLocationSuggestions(
  e: Event,
  locationsWrapper: HTMLElement,
  locationInput: HTMLInputElement
) {
  if (locationsWrapper.hidden === true && e.target == locationInput) {
    locationsWrapper.hidden = false;
    return;
  }

  locationsWrapper.hidden = true;
}

/**
 * Suppression des suggestions de lieux.
 * ****************************************
 */
function cleanLocationSuggestions(locationsSearchResultsWrapper: HTMLElement) {
  locationsSearchResultsWrapper.innerHTML = "";
}

/**
 * Repositionnement de la carte en fonction d'une coordonnée.
 * ****************************************
 */
function flyTo(map: any, longitude: number, latitude: number, zoom: number) {
  map.flyTo({
    center: [longitude, latitude],
    zoom: zoom,
  });
}

/**
 * Repositionnement de la carte en fonction de plusieurs coordonnées.
 * ****************************************
 */
function fitBounds(map: any, place: Place, suggestedStores: any[]) {
  const coordinates: any[] = [];

  suggestedStores.forEach((suggestedStore) => {
    const longitude = suggestedStore.coordinates.longitude;
    const latitude = suggestedStore.coordinates.latitude;
    coordinates.push([latitude, longitude]);
  });

  // Récupérer les bounds du nuage de coordonnées.
  let arr: any[] = [];

  coordinates.forEach((coordinate) => {
    arr.push({ lat: coordinate[0], lng: coordinate[1] });
  });

  arr.push({
    lat: place.coordinates.latitude,
    lng: place.coordinates.longitude,
  });

  const result = arr.reduce(
    function (prev, curr) {
      if (curr.lat < prev.min_cords.lat) prev.min_cords.lat = curr.lat;
      if (curr.lat > prev.max_cords.lat) prev.max_cords.lat = curr.lat;
      if (curr.lng < prev.min_cords.lng) prev.min_cords.lng = curr.lng;
      if (curr.lng > prev.max_cords.lng) prev.max_cords.lng = curr.lng;
      return prev;
    },
    {
      min_cords: { lat: Infinity, lng: Infinity },
      max_cords: { lat: -Infinity, lng: -Infinity },
    }
  );

  let bounds = [
    [result.max_cords.lng, result.max_cords.lat],
    [result.min_cords.lng, result.min_cords.lat],
  ];

  map.fitBounds(bounds, { padding: 150 });
}

/**
 * Récupèration de l'ensemble des distances entre le lieux sélectionné et les magasins.
 * ****************************************
 */
function getDistances(place: Place, stores: Store[]) {
  const distances: number[] = [];

  stores.forEach((store: Store) => {
    const id = store.id;
    const distance = calcDistance(place, store);
    distances[id] = distance;
  });

  return distances;
}

/**
 * Calcule de la distance entre 2 coordonnées.
 * ****************************************
 */
function calcDistance(place: Place, store: Store) {
  const to = [place.coordinates.longitude, place.coordinates.latitude];
  const from = [store.coordinates.longitude, store.coordinates.latitude];
  const distance = turf.distance(to, from);

  return distance;
}

/**
 * Récupération des clés des plus petites distances du tableau.
 * ****************************************
 */
function getNearestDistances(input: number[], count: number) {
  const output: number[] = [];

  Object.keys(input).forEach((key: string) => {
    output.push(parseInt(key));

    if (output.length > count) {
      output.sort(function (a, b) {
        return input[a] - input[b];
      });

      output.pop();
    }
  });

  return output;
}

/**
 * Mise à jour de la suggestion de magasins.
 * ****************************************
 */
function updateStoreSuggestions(
  storeListItems: HTMLElement[],
  storesToDisplay: any[]
) {
  /**
   * Change le compteur de store.
   */
  const counter = document.querySelector<HTMLElement>(".store-locator__count");
  let string =
    "Tous les résultats (" + Object.keys(storesToDisplay).length + ")";
  if (counter) {
    counter.innerHTML = string;
  }

  storeListItems.forEach((storeListItem: HTMLElement) => {
    if (!storeListItem.dataset.id) return;

    const id = parseInt(storeListItem.dataset.id);
    storeListItem.hidden = true;

    if (!storesToDisplay[id]) return;

    storeListItem.hidden = false;
  });
}

/**
 * Réinitialisation de la position de la carte.
 * ****************************************
 */
function resetMapPosition(map: any, mapConfiguration: MapConfiguration) {
  flyTo(
    map,
    mapConfiguration.longitude,
    mapConfiguration.latitude,
    mapConfiguration.defaultZoom
  );
}

/**
 * Réinitialisation de la liste de suggestions des magasins.
 * ****************************************
 */
function cleanStoreSuggestions(storeListItems: HTMLElement[]) {
  storeListItems.forEach((storeListItem: HTMLElement) => {
    storeListItem.hidden = false;
  });
  const counter = document.querySelector<HTMLElement>(".store-locator__count");
  let string = "Tous les résultats (" + storeListItems.length + ")";
  if (counter) {
    counter.innerHTML = string;
  }
}

async function getUserLocation(mapboxAPI: string, mapboxAccessToken: string) {
  const position: any = await new Promise((res: any, rej: any) => {
    navigator.geolocation.getCurrentPosition(res, rej);
  });

  if (!position.coords) return;

  const location: any = await getLocation(
    mapboxAPI,
    mapboxAccessToken,
    position.coords
  );

  return location;
}

async function getLocation(
  mapboxAPI: string,
  mapboxAccessToken: string,
  userPosition: any
) {
  const params = `${userPosition.longitude},${userPosition.latitude}.json?country=fr&language=fr&types=place`;
  const request = `${mapboxAPI}geocoding/v5/mapbox.places/${params}&access_token=${mapboxAccessToken}`;
  const response = await fetch(request).then((res) => res.json());

  return response;
}

function getNearestStores(
  storeListItems: HTMLElement[],
  distances: number[],
  nbStoresToFind: number
) {
  const nearestStores: any[] = [];
  const nearestDistances: number[] = getNearestDistances(
    distances,
    nbStoresToFind
  );

  storeListItems.forEach((storeListItem: HTMLElement) => {
    const id = storeListItem.dataset.id;
    const name = storeListItem.dataset.name;
    const longitude = storeListItem.dataset.longitude;
    const latitude = storeListItem.dataset.latitude;

    if (
      !id ||
      !name ||
      !longitude ||
      !latitude ||
      !nearestDistances.includes(parseInt(id))
    ) {
      return;
    }

    const nearestStore: any = {
      id: parseInt(id),
      name: name,
      coordinates: {
        longitude: parseFloat(longitude),
        latitude: parseFloat(latitude),
      },
    };

    nearestStores[parseInt(id)] = nearestStore;
  });

  return nearestStores;
}

/**
 * Lancement de la recherche.
 * ****************************************
 */
async function makeSearch(
  map: any,
  mapboxAPI: string,
  mapboxAccessToken: string,
  locationInput: HTMLInputElement,
  locationButton: HTMLElement,
  locationsWrapper: HTMLElement,
  locationsSearchResultsWrapper: HTMLElement,
  stores: Store[],
  storeLocatorMap: HTMLElement,
  storeListItems: HTMLElement[],
  targetedLocation: HTMLElement | undefined,
  searchValue: string,
  autoSearch: boolean
) {
  /**
   * Récupération des nouvelles suggestions depuis l'API Mapbox.
   * ****************************************
   */
  const locationSuggestions = await getLocationSuggestions(
    mapboxAccessToken,
    mapboxAPI,
    searchValue
  );

  if (
    !locationSuggestions.features ||
    locationSuggestions.features.length == 0
  ) {
    cleanLocationSuggestions(locationsSearchResultsWrapper);
    return;
  }

  /**
   * Suppression des anciennes suggestions de lieux.
   * ****************************************
   */
  cleanLocationSuggestions(locationsSearchResultsWrapper);

  /**
   * Insertion des nouvelles suggestions de lieux.
   * ****************************************
   */
  insertLocations(locationsSearchResultsWrapper, locationSuggestions.features);

  /**
   * Affichage de l'élément HTML contenant les suggestions de lieux.
   * ****************************************
   */
  if (locationsWrapper.hidden === true) {
    locationsWrapper.hidden = false;
  }

  /**
   * Récupération des suggestions de lieux présentes dans le DOM.
   * ****************************************
   */
  const suggestionListItems = Array.from(
    locationsWrapper?.querySelectorAll<HTMLElement>(".group__result")
  );

  /**
   * Ajout de l'écoute de la suggestion au clic.
   * ****************************************
   */
  if (suggestionListItems.length == 0) return;

  suggestionListItems.forEach((suggestion: HTMLElement) => {
    suggestion.onclick = () => {
      const name = suggestion.dataset.name;
      const longitude = suggestion.dataset.longitude;
      const latitude = suggestion.dataset.latitude;
      const department = suggestion.dataset.department;

      if (!name || !longitude || !latitude || !department) return;

      const place: Place = {
        name: name,
        department: department,
        coordinates: {
          longitude: parseFloat(longitude),
          latitude: parseFloat(latitude),
        },
      };

      /**
       * Insertion de la localisation dans le champ recherche.
       * ****************************************
       */
      locationInput.focus();
      locationInput.value = place.name;

      /**
       * Récupération des magasins référents en fonction de la suggestion de lieu.
       * ****************************************
       */
      let referralStores: Store[] = [];
      let suggestedStores: Store[] = [];

      stores.forEach((store) => {
        if (!store.referentOf.includes(place.department)) return;
        referralStores[store.id] = store;
      });

      if (referralStores.length > 0) {
        /**
         * Assignation des magasins référents aux magasins suggérés.
         * ****************************************
         */
        suggestedStores = referralStores;
      } else {
        /**
         * Calcul des distances entre l'emplacement de l'utilisateur et les magasins.
         * ****************************************
         */
        const distances = getDistances(place, stores);

        /**
         * Récupération des 3 centres Securom les plus proches de l'emplacement saisie.
         * ****************************************
         */
        suggestedStores = getNearestStores(storeListItems, distances, 3);
      }

      /**
       * Mise à jour des suggestions de magasins.
       * ****************************************
       */
      updateStoreSuggestions(storeListItems, suggestedStores);

      const markers: HTMLElement[] = getMarkers(storeLocatorMap);

      if (markers.length > 0) {
        hideMarkers(markers);
        removeLocationMarker(markers);
        showMarkers(markers, suggestedStores);
      }

      setLocationMarker(map, place);

      /**
       * Repositionnement de la carte en fonction de multiples coordonnées.
       * ****************************************
       */
      fitBounds(map, place, suggestedStores);
    };
  });

  if (locationButton) {
    locationButton.onclick = () => {
      const selectedPlace = suggestionListItems[0];

      if (!selectedPlace.dataset.name) return;

      selectedPlace.click();
      locationInput.focus();
      locationInput.value = selectedPlace.dataset.name;
    };

    if (autoSearch == true) {
      locationButton.click();
    }
  }

  locationInput.onkeydown = (e) => {
    if (e.code != "Enter") return;
    targetedLocation = suggestionListItems[0];

    if (!targetedLocation.dataset.name) return;

    targetedLocation.click();
    locationInput.focus();
    locationInput.value = targetedLocation.dataset.name;
  };
}
