export default function ShowDesiredMenu(
  body: HTMLElement,
  header: HTMLElement
) {
  /**
   * Vérification de l'éxistence du cta et du volet des familles de produit
   * ****************************************
   */
  const showProductFamiliesButton = header.querySelector<HTMLElement>(
    ".menu__link--products"
  );

  const showProductFamiliesMenu =
    header.querySelector<HTMLElement>(".header__products");

  if (showProductFamiliesButton && showProductFamiliesMenu) {
    showProductFamiliesButton.dataset.menu = "show-product-families";
  }

  /**
   * Vérification de l'éxistence du cta et du volet de la recherche produit
   * ****************************************
   */
  const searchProductButton = header.querySelector(
    ".topbar__search-loop"
  ) as HTMLElement;

  const searchProductMenu = header.querySelector(
    ".header__search"
  ) as HTMLElement;

  const searchProductInput =
    searchProductMenu.querySelector<HTMLInputElement>(".search__input");

  if (searchProductButton && searchProductMenu) {
    searchProductButton.dataset.menu = "search-product";
  }

  /**
   * Vérification de l'éxistence du cta et du menu principal
   * ****************************************
   */
  const mobileMenuButton = document.querySelector(
    ".open-mobile-menu"
  ) as HTMLElement;

  const mobileMenu = header.querySelector(".topbar__menu") as HTMLElement;

  if (mobileMenuButton && mobileMenu) {
    mobileMenuButton.dataset.menu = "mobile-main";
  }

  /**
   * Affichage du menu concerné au clic sur le cta
   * ****************************************
   */
  const menuButtons = Array.from(
    document.querySelectorAll<HTMLElement>("[data-menu]")
  );

  menuButtons?.forEach((button) => {
    button.onclick = () => {
      if (body.dataset.menuStatus == button.dataset.menu) {
        body.dataset.menuStatus = "close";

        return;
      }

      body.dataset.menuStatus = button.dataset.menu;

      if (button.dataset.menu == "search-product" && searchProductInput) {
        searchProductInput.focus();
      }
    };
  });

  /**
   * Switch d'affichage entre familles et marques
   * ****************************************
   */
  const selectedDisplay = header.querySelector(
    "[data-selected-display]"
  ) as HTMLElement;

  const displayButtons = Array.from(
    header.querySelectorAll<HTMLElement>("[data-link-display]")
  );

  displayButtons?.forEach((button) => {
    button.onclick = () => {
      if (
        selectedDisplay.dataset.selectedDisplay == button.dataset.linkDisplay
      ) {
        return;
      }

      selectedDisplay.dataset.selectedDisplay = button.dataset.linkDisplay;
    };
  });
}
